.scrollbar::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.2);
}

.scrollbar::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}

.scrollbar::-webkit-scrollbar-corner {
	background-color: transparent;
	border-color: transparent;
}

.scrollbar-overlay {
	overflow-y: overlay;
}