body {
	overflow-y: overlay;
}

body::-webkit-scrollbar {
	width: 15px;
	height: 15px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4);
}

body::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar-corner {
	background-color: transparent;
	border-color: transparent;
}